<template>
    <div class="list">
        <div><img src="../assets/head3.png"></div>
        <div class="exlist">
            <ul>
                <li v-for="(item,index) of data" :key="index">
                    <div class="image"><img src="../assets/list.png"></div>
                    <div class="title">{{item.name}}</div>
                    <div class="btn"><button @click="submit(item.id)">去兑换</button></div>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import {goodslist} from '@/api'
export default {
    name:'List',
    data() {
        return{
            data:[]
        }
    },
    methods:{
        async getlist(){
            const result = await goodslist()
            this.data = result.data.data.goods_list
        },
        submit(id){
            this.$router.push({
                path:'/exchange',
                query:{id:id}
            })
        }
    },
    created(){
        this.getlist()
    }
}
</script>
<style lang="scss" scoped>
    .list{
        text-align: center;
        img{
            width: 5.7rem;
            height: .42rem;
        }
        .exlist{
            ul{
                padding: .3rem;
                li{
                    display: flex;
                    background: white;
                    border-radius: 10px;
                    font-size: 0.26rem;
                    align-items: center;
                    margin: 0.3rem 0;
                    box-shadow: 1px 1px 1px #d6d6d6;
                    .image{
                        width: 1.5rem;
                        height: 1.5rem;
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .title{
                        width: 4rem;
                        text-align: left;
                        margin-left: 0.1rem;
                    }
                    .btn{
                        button{
                            background: #E84149;
                            color: white;
                            padding: 0.05rem 0.2rem;
                            border:none;
                            border-radius: 30px;
                        }
                        
                    }
                }
            }
        }
    }
</style>